<template>
  <div>
    <div v-if="getLoaded()" class="duck-form form-width-s3 form-center">
      <div class="data-control">
        <div class="btn-set">
          <router-link :to="'/property/'+slug">
            <i class="fa fa-angle-left"></i> Back <span class="mobile-hide">to property list</span>
          </router-link>
        </div>
      </div>
      <NotiBar v-if="msgShow" :type="msgType" :msg="msgText" />
      <!--   -->

      <form @submit.prevent="saveForm">
        <fieldset class="form-block">
          <div class="form-container">
            <div class="input row2">
              <div class="col" :class="{ error: $v.property.name.$error }">
                <Label text="Proproty name" :required="true" />
                <input type="text" v-model.trim="$v.property.name.$model">
              </div>
              <div class="col" :class="{ error: $v.property.priority.$error }">
                <Label text="Priority" :required="true" />
                <input type="number" v-model.trim="$v.property.priority.$model">
              </div>
            </div>
            <div class="input row2">
              <div class="col">
                <Label text="Refer ID" />
                <input type="text" v-model="property.refer">
              </div>
              <div class="col">
                <Label text="Status" />
                <select name="u_status" v-model="property.status">
                  <option value="0">Disable</option>
                  <option value="9">Enable</option>
                </select>
              </div>
            </div>
            <div class="input">
              <Label text="Remark:" />
              <textarea v-model="property.remark" rows="3"></textarea>
            </div>
          </div> 
        </fieldset>

        <div class="button-block">
          <button type="submit" class="btn btn-update">
            <i class="fa fa-save fa-lg"></i> Save
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import {property} from '@/variables/const.js';
const { required, numeric} = require('vuelidate/lib/validators')

import NotiBar from '@/components/common/NotiBar.vue';
import Label from '@/components/common/form/Label.vue';

export default {
  components: {
    NotiBar,
    Label
  },
  data() {
    return {
      loaded: false,
      
      is_add: true,
      slug: '',

      msgShow: false,
      msgType:'success',
      msgText:'',

      propertyId: '',
      refer_props: {},
      property: {
        type:     '',
        name:     '',
        refer:    '',
        priority: 0,
        remark:   '',
        status:   9,
      }
    }
  },
  validations: {
    property: {
      name: {required},
      priority: {required, numeric},
    }
  },
  created() {
    this.setForm();
  },
  watch:{
    $route() {
      this.setForm();

      this.msgShow = true;
      this.msgText = 'Save data complete';
    }
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    setForm:function() {
      this.$store.commit('setLoaderShow', true);

      this.slug = this.$route.params.slug;
      this.refer_props = property[this.slug];

      if(!this.$route.params.id) {
        this.property.type = this.slug;
        this.$store.commit('setPageTitle', 'Add Property : ' + this.refer_props.name);
        this.$store.commit('setHeadTitle', 'Add Property : ' + this.refer_props.name);
        this.$store.commit('setLoaderShow', false);
      }else{
        this.is_add = false;
        this.propertyId = this.$route.params.id;

        this.$store.commit('setPageTitle', 'Edit Property : ' + this.refer_props.name);
        this.$store.commit('setHeadTitle', 'Edit Property : ' + this.refer_props.name);

        apiRequest
          .get('property/'+this.slug+'/'+this.propertyId)
          .then( (res) => {
            this.property = res.data.result;
            this.$store.commit('setLoaderShow', false);
          });
      }
    },
    resetForm() {
      
    },
    saveForm:function(e) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        // this.err_message = 'กรุณากรอกข้อมูลให้ครบถ้วน';
      } else {
        this.$store.commit('setLoaderShow', true);

        apiRequest
          .post(
            '/property/save' + (this.propertyId==='' ? '' : '/'+this.propertyId), 
            this.property
          )
          .then( (res) => {
            console.log(res.data);
            if(this.is_add) {
              this.$router.push('/property/'+this.slug+'/edit/' + res.data.last_insert_id).catch();
            }else{
              this.setForm();
              this.msgShow = true;
              this.msgText = 'Save data complete';
            }
          });
      }
      e.preventDefault();
    },
  }
}
</script>


<style scoped>
.btn-set {
  float: none;
}
</style>